import React, { Component } from 'react';

import {
    Route,
    Switch,
    Redirect,
    withRouter
} from "react-router-dom"

import LotsPage from './LotsPage'
import LotDetailPage from './LotDetailPage'
import BuyPage from './BuyPage'
import BuyCompletePage from './BuyCompletePage'
import OnboardingPage from './OnboardingPage'
import Onboarding2Page from './Onboarding2Page'
import PersonPage from '../PersonPage/PersonPage'
//import MetaTags from 'react-meta-tags';

import api from '../../utils/api';

class App extends Component {
    render() {
        const { history } = this.props
 

        return (
            <div className="App">
                <Switch>
                    <Route history={history} path='/onboarding' component={OnboardingPage} />
                    <Route history={history} path='/onboarding2' component={Onboarding2Page} />
                    <Route history={history} path='/lots' component={LotsPage} />
                    <Route history={history} path='/lotDetail/:lotId' component={LotDetailPage} />
                    <Route history={history} path='/buy/:lotId' component={BuyPage} />
                    <Route history={history} path='/buyComplete' component={BuyCompletePage} />
                    <Route history={history} path='/person/:id' component={PersonPage} />
                    <Redirect from='/' to='/onboarding'/>
                </Switch>
            </div>
        );
    }
}

export default withRouter(App)