import React, { Component, useState } from 'react'
import {Link} from "react-router-dom";
import GoogleLogin from 'react-google-login';
import { RWebShare } from "react-web-share";

import styles from './LotDetailPage.module.css';

import {
    isMobile, isSafari
} from "react-device-detect";
import {Input, Row, Col, Layout, Typography, Tag, Avatar, Space, Button, Carousel, Drawer, Divider} from 'antd';
import {useHistory, withRouter} from 'react-router-dom';

import {LikeTwoTone, DislikeTwoTone, StarOutlined, ShareAltOutlined} from "@ant-design/icons";
import Star from "./Icons/star";
import FreekinLogo from "./Icons/logo";

import FormOrder from '../FormOrder/FormOrder';
const { Title, Text } = Typography;
const { Header, Footer, Sider, Content } = Layout;



//const siteRoot = "/freekin.site";
const siteRoot = ".";

class LotDetailPage extends React.Component {
    
    constructor(props) {
        super(props);
        //this.goToHome = this.goToHome.bind(this);
        //this.goToBuy = this.goToBuy.bind(this);
        this.state = {
            error: null,
            isLoaded: false,
            items: [],
            authAvatarImageUrl: "https://developers.google.com/identity/images/g-logo.png",
            authLoginText: navigator.language.includes("ru") ? "Войти" : "Login",
            authUserId: "",
            authAccessToken: "",
            usersProperties: [],
            currentLotIndex: -1,
            visible: false,
            contactsEmail: "",
            contactsTelegram: "",
            contactsWhatsapp: ""
        };
    }

    goToHome(){
        window.location.href = '/onboarding'

    }
    goToBuy(imgName){
        window.location.href = siteRoot + '/buyComplete/' + imgName;
    }

    goToPersonPage(id){
        //Из-за того, что на странице отключен роутинг react, приходится делать так
        //А роутинг клиентский отключен по причине того, что я пока не понял, как совместить его с серверным
        //Серверный нужен для генерации привью
        window.location.href = '/person/' + id;
    }

    goToBackToList(lotId, lotNumber)
    {
        //localStorage.setItem('lastDetailLotNumber', lotNumber);
        //alert(localStorage.getItem('lastDetailLotNumber'));
        this.props.history.goBack();
        //this.props.history.push('/lots');
    }
    
    componentDidMount() {
        
        fetch("https://igosh.pro/freekin.server/lot/")
        //fetch("http://localhost:5000/lot/") 
            .then(res => res.json())
            .then(
                (result) => {
                    this.setState({
                        isLoaded: true,
                        items: result,
                        currentLotIndex: 0
                    });
                },
                // Примечание: важно обрабатывать ошибки именно здесь, а не в блоке catch(),
                // чтобы не перехватывать исключения из ошибок в самих компонентах.
                (error) => {
                    this.setState({
                        isLoaded: true,
                        error
                    });
                }
            )
    }
    
    render() {
        

        const { error, isLoaded, items, visible } = this.state;
        const { classes } = this.props;
        const lotId = this.props.match.params.lotId;
        //const currentLot = geLotById(lotId).length;
        

        const showDrawer = () => {
            this.setState({
                visible: true
            });
        };

        const onClose = () => {
            this.setState({
                visible: false
            });
        };
        
        const buyLot = () => {
            let contacts = "";
            if(this.state.contactsEmail !== ''){
                contacts = this.state.contactsEmail;
            }
            if(this.state.contactsTelegram !== ''){
                if(contacts !== ''){
                    contacts += ',';
                }
                contacts = this.state.contactsTelegram;                
            }
            if(this.state.contactsWhatsapp !== ''){
                if(contacts !== ''){
                    contacts += ',';
                }
                contacts += this.state.contactsWhatsapp;
            }
            
            if(contacts.length > 0) {
                const requestOptions = {
                    method: 'POST',
                    headers: {'Content-Type': 'application/json'},
                    body: JSON.stringify({email: this.state.contactsEmail, contacts: contacts})
                };
                fetch("https://igosh.pro/freekin.server/buy/" + lotId, requestOptions)
                    .then(function (response){
                        if(response.ok){
                            //alert('Ok');
                            //this.goToBuy(geLotById(lotId).medias[0].url);
                            //context.goToBuy('1.jpg');
                            window.location.href = siteRoot + '/buyComplete/';
                        } else{
                            if(response.status === 409){
                                alert('На данный email уже отправлялся выбранный лот');
                            } else{
                                alert('Ошибка, пожалуйста повторите ваш запрос позже');
                            }
                        }
                    });
            } else{
                alert("Пожалуйста, укажите корректный адрес email и один из контактов Telegram/Whatsapp");
            }
        }
        
        function geLotById(Id){
            if(items.length > 0){
                return items.find(obj => {return obj.id === Id})
            }
            return {number:"", location:""};
        }

        function getAuthorId(){
            let lot = geLotById(lotId);
            if(lot){
                return lot.authorId;
            }
            return "";
        }
        
        function getCurrentLotAuthorImageUrl(){
            let lot = geLotById(lotId);
            if(lot.number !== ""){
                return lot.authorImageUrl;
            }
            return "";
        }

        function getCurrentLotAuthorName(){
            let lot = geLotById(lotId);
            if(lot.number !== ""){
                return lot.authorName;
            }
            return "";
        }

        function getCurrentLotNumber(){
            let lot = geLotById(lotId);
            if(lot.number !== ""){
                return lot.number;
            }
            return "";
        }

        function getCurrentLotPrice(){
            let lot = geLotById(lotId);
            if(lot.number !== ""){
                return lot.price;
            }
            return "";
        }

        function getCurrentLotCaption(){
            let lot = geLotById(lotId);
            if(lot.number !== ""){
                return lot.caption;
            }
            return "";
        }
        
        function getCurrentLotLocation(){
            let lot = geLotById(lotId);
            if(lot.number !== ""){
                return lot.location;
            }
            return "";
        }

        function getCurrentLotDescription(){
            let lot = geLotById(lotId);
            if(lot.number !== ""){
                return lot.description;
            }
            return "";
        }
        
        ///ToDo:затычка, надо будет на сервере в структуре лота выделить отдельную настройку под основное фото
        function getLotImagesWhithoutFirst(){
            return geLotById(lotId).medias.filter(item => item.type !== 1);
        }
        
        if (error) {
            return <div>Ошибка: {error.message}
            </div>;
        } else if (!isLoaded) {
            return <div>Загрузка...</div>;
        } else
        {
            return (
                <Layout style={{height:"170vh"}}>
                    <Layout style={{backgroundColor:'white', height:'5vh'}} onClick={() => this.goToBackToList(lotId, geLotById(lotId).number)}>
                        <Row>
                            <Col span={1} style={{marginTop:'10px'}}>
                                <span className={styles.button_back} />
                            </Col>
                            <Col span={23} style={{marginTop:'10px'}} >
                                <div align="middle" >
                                    <FreekinLogo color='black' />
                                </div>
                            </Col>
                        </Row>
                    </Layout>
                    <Layout style={{backgroundColor:'white', height:'5vh'}}>
                        <Space style={{marginLeft:'20px', marginTop:'0px'}} onClick={() =>this.goToPersonPage(getAuthorId())}>
                            <Avatar
                                src={getCurrentLotAuthorImageUrl()}
                            />
                            <Text style={{color:'black', marginLeft:'0px', marginTop:'0px'}}>
                                {getCurrentLotAuthorName()}
                            </Text>
                        </Space>
                    </Layout>
                    <Layout style={{backgroundColor:'white', height:'60vh'}}>
                        <Content style={{marginLeft:'0px', marginRight:'0px', marginTop:'0px'}}>
                            <Carousel effect="scrollx" dotPosition={"top"}>
                                {getLotImagesWhithoutFirst().map(item=>{
                                    return (
                                        <div key={item.id}>
                                            <Layout style={{height:'60vh', backgroundImage: 'url(' + item.url + ')', backgroundSize:'cover', backgroundPosition:'center', backgroundRepeat:"no-repeat"}}>
                                            </Layout>
                                        </div>
                                    )
                                })}
                            </Carousel>
                        </Content>
                    </Layout>
                    <Layout style={{backgroundColor:'white', height:'70vh'}}>
                        <Row>
                            <Col span={21}>
                                <Content style={{marginLeft:'20px', textAlign:'left', marginRight:'20px', marginTop:'5px', fontFamily:'Reforma-Grotesk'}}>
                                    <Space style={{verticalAlign:"middle"}}>
                                        <Title level={4} style={{color:'white', textAlign:'left', backgroundColor:'black', padding:'5px', marginTop:'5px'}}>
                                            ЛОТ #{getCurrentLotNumber()}
                                        </Title>
                                        <Title level={3} style={{color:'black', marginLeft:'0px', padding:'5px', marginTop:'5px'}}>
                                            {getCurrentLotPrice()} ₽
                                        </Title>
                                    </Space>
                                </Content>
                            </Col>
                            <Col span={3}>
                                <Content style={{marginRight:'20px', marginTop:'10px', verticalAlign:"middle"}}>
                                    <RWebShare closeText={"Закрыть"} 
                                               data={{
                                                   text: geLotById(lotId).tizer,
                                                   url: window.location.href,
                                                   title: geLotById(lotId).caption,
                                               }}
                                               onClick={() => console.log("shared successfully!")}
                                               sites={['copy', 'facebook', 'telegram', 'whatsapp', 'mail']}>
                                        <button style={{backgroundColor:'transparent', borderWidth:'0px'}}>
                                            <ShareAltOutlined style={{fontSize:"xx-large", marginTop:'0px', marginRight:'0px'}} />
                                        </button>
                                    </RWebShare>
                                </Content>
                            </Col>
                        </Row>
                        <Row>
                            <Col span={24}>
                                <Content style={{marginLeft:'20px', textAlign:'left', marginRight:'20px', fontFamily:'Reforma-Grotesk'}}>
                                    <Title level={4} style={{color:'black', marginTop:'0px'}}>
                                        {getCurrentLotCaption()}
                                    </Title>
                                    <Text secondary style={{marginTop:'0px'}}>
                                        {getCurrentLotLocation()}
                                    </Text>
                                </Content>
                            </Col>
                        </Row>
                        <Row>
                            <Col span={24}>
                                <Content style={{marginLeft:'20px', textAlign:'justify', marginRight:'20px', marginTop:'10px'}}>
                                    <Text secondary>
                                        {getCurrentLotDescription()}
                                    </Text>
                                </Content>
                            </Col>
                        </Row>
                        <Row>
                            <Title level={5} strong style={{marginTop:'20px',marginLeft:'20px', textAlign:'left'}}>
                                Что внутри лота
                            </Title>
                        </Row>
                        <Row>
                            {geLotById(lotId).insides.map(item=>{
                                return (
                                    <Col span={24}>
                                        <div key={item.id}>
                                            <Row>
                                                <Space style={{marginLeft:'20px'}}>
                                                    <Star color='black'/>
                                                    <Text>
                                                        {item.name}
                                                    </Text>
                                                </Space>
                                             </Row>
                                        </div>
                                    </Col>
                                )
                            })}
                        </Row>
                        <Row>
                            <Col span={24} style={{marginTop:'20px', textAlign:'left'}}>
                                <Space style={{marginLeft:'20px', marginRight:'20px'}}>
                                    {geLotById(lotId).tags.map(item=>{
                                        return (
                                            <div key={item.id}>
                                                <Tag color={"#2656FA"} style={{margin:'0px', borderRadius:'10px'}}>{item.name}</Tag>
                                            </div>
                                        )
                                    })}
                                </Space>
                            </Col>
                        </Row>
                    </Layout>
                    <Layout style={{backgroundColor:'white', height:'10vh'}}>
                        <Content style={{marginLeft:'20px', marginRight:'20px'}}>
                            <Text style={{marginTop:'0px'}}>
                                Если хочешь стать автором или у тебя есть вопросы, предложения, пиши на почту: <a href='mailto:freekman@freekinapp.com'>freekman@freekinapp.com</a> или в Telegram: <a href='https://t.me/freekinit'>@freekinit</a> 
                            </Text>
                        </Content>
                    </Layout>
                    <Footer style={{backgroundColor:'white', height:'12vh'}}>
                        <Button type="primary" size={"large"} block style={{backgroundColor:'black', transform:'rotate(-3deg)'}} onClick={showDrawer}>Получить секрет</Button>
                    </Footer>
                    {visible && <FormOrder visible={visible} onClose={onClose} lotId={lotId} cardNumber={getCurrentLotNumber()} />}
                </Layout>
            );
        }
    }
}

export default LotDetailPage;
