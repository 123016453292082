import React from 'react';

function ButtonCancel(props)
{
    const {circleColor, iconColor} = props;
    return (
        <svg width="68" height="68" viewBox="0 0 68 68" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="34" cy="34" r="34" fill={circleColor}/>
            <path d="M34.9799 32.7573C36.3333 34.5233 37.6938 35.953 39.693 36.9117C40.645 37.3683 41.6251 37.918 42.2978 38.625C43.5246 39.915 45.0699 40.7393 46.7329 41.5507C48.2137 42.273 49.4666 43.327 50.8454 44.2517C50.8968 44.985 50.9423 45.6353 51 46.4593C49.8376 46.8867 48.7243 47.2963 47.7747 47.6457C47.2479 47.4233 46.8733 47.368 46.7116 47.1813C43.3261 43.2803 39.1164 40.11 34.4574 37.3407C33.9575 37.0437 33.3658 36.8267 32.7797 36.671C32.3478 36.556 31.8562 36.6013 31.2641 36.5677C28.1784 39.4247 25.9047 42.74 23.9925 46.2283C23.292 47.5067 22.3737 48.457 20.8518 49C18.3977 48.0057 18.3918 48.0117 17 46.2517C17.6126 44.5227 19.3758 42.1723 20.8518 41.5507C23.6713 38.233 26.2406 36.7473 29 33.5C28.5994 32.9813 28.6942 32.7033 28.2433 32.368C24.9409 29.9107 22.4923 26.694 18.7774 24.6327C17.4485 23.8953 17.4999 22.959 17.1325 21.8337C18.041 21.574 18.7505 21.371 19.4462 21.172C22.0051 22.3187 22.9922 24.578 25.0429 25.9687C27.0018 27.297 28.7622 28.8333 30.5008 30.193C32.856 30.3223 33.9808 29.6177 35.2508 28.0527C37.3022 25.5243 37.7472 22.374 40.5568 19.8337C41.2442 19.6273 42.3781 19.2853 43.3249 19C45.0022 19.9657 45.2688 21.0027 44.4141 22.4653C41.8944 23.949 39.5182 25.758 38.3183 28.557C37.6969 30.0047 36.253 31.2017 34.9799 32.7573Z" fill={iconColor}/>
        </svg>
    );
}

export default ButtonCancel;
