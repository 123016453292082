import React, {useEffect} from 'react'
import ReactDOM from 'react-dom'
import { Router } from "react-router-dom"
import {createBrowserHistory} from 'history'
import './index.css';
import './Components/App/App.css';

import App from './Components/App/App'

// создаём кастомную историю
const history = createBrowserHistory()

ReactDOM.render((
    <Router history={history}>
        <App/>
    </Router>

    ), document.getElementById('root')
); 