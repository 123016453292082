import React, { Component } from 'react'
import {Link} from "react-router-dom";
import GoogleLogin from 'react-google-login';

import {
    isMobile, isSafari
} from "react-device-detect";
import {Grid, Row, Col, Layout, Typography, Image, Avatar, Space, Button} from 'antd';
import {useHistory, withRouter} from 'react-router-dom';

import {LikeTwoTone, DislikeTwoTone} from "@ant-design/icons";
import styles from "./BuyPage.module.css";
import FreekinLogo from "./Icons/logo";
const { Title, Text } = Typography;
const { Header, Footer, Sider, Content } = Layout;


class BuyPage extends React.Component {
    
    constructor(props) {
        super(props);
        this.goToHome = this.goToHome.bind(this);
        this.goToLotDetail = this.goToLotDetail.bind(this);
        this.state = {
            error: null,
            isLoaded: false,
            lot: null,
            authAvatarImageUrl: "https://developers.google.com/identity/images/g-logo.png",
            authLoginText: navigator.language.includes("ru") ? "Войти" : "Login",
            authUserId: "",
            authAccessToken: "",
            usersProperties: [],
            currentLotIndex: -1
        };
    }

    goToHome(){
        this.props.history.push('/onboarding');
    }

    goToLotDetail(lotId){
        this.props.history.push('/lotDetail/' + lotId);
    }

    goToBackToList()
    {
        this.props.history.goBack();
    }

    componentDidMount() {
        
        fetch("https://igosh.pro/freekin.server/lot/" + this.props.match.params.lotId)
            .then(res => res.json())
            .then(
                (result) => {
                    this.setState({
                        isLoaded: true,
                        lot: result
                    });
                },
                // Примечание: важно обрабатывать ошибки именно здесь, а не в блоке catch(),
                // чтобы не перехватывать исключения из ошибок в самих компонентах.
                (error) => {
                    this.setState({
                        isLoaded: true,
                        error
                    });
                }
            )
    }
    render() {

        const { error, isLoaded, lot } = this.state;
        const successUrl = "https%3A%2F%2Ffreekin.ru%2FbuyComplete";    
        const accountId = "4100117240717756";//Kate account
        //const accountId = "410016992369640";//Sergey's account
        let price = "300";
        let iframeSrc = "";
        let lotImageUrl = "";
        if(lot != null){
            lotImageUrl = lot.medias.find(i=>i.type === 1).url;
            price = lot.price;
            iframeSrc = "https://yoomoney.ru/quickpay/shop-widget?writer=seller&targets=%D0%91%D0%BB%D0%B0%D0%B3%D0%BE%D0%B4%D0%B0%D1%80%D0%BD%D0%BE%D1%81%D1%82%D1%8C%20%D0%B0%D0%B2%D1%82%D0%BE%D1%80%D1%83%20%D0%B7%D0%B0%20%D1%81%D0%B5%D0%BA%D1%80%D0%B5%D1%82%20%D0%BA%20%D0%BB%D0%BE%D1%82%D1%83&targets-hint=&default-sum=" + price + "&button-text=11&payment-type-choice=on&phone=on&hint=&successURL=" + successUrl + "&quickpay=shop&account=" + accountId;
        }
        
        if (error) {
            return <div>Ошибка: {error.message}
            </div>;
        } else if (!isLoaded) {
            return <div>Загрузка...</div>;
        } else
        {
            return (
                <Layout style={{height:'100vh', backgroundImage: 'url(' + lotImageUrl + ')', backgroundSize:'cover', backgroundPosition:'unset', backgroundRepeat:"no-repeat"}}>
                    <Content>
                        <Layout style={{backgroundColor:'transparent', height:'5vh'}} onClick={() => this.goToBackToList()}>
                            <Row>
                                <Col span={1} style={{marginTop:'10px'}}>
                                    <span className={styles.button_back} />
                                </Col>
                                <Col span={23} style={{marginTop:'10px'}} >
                                    <div align="middle" >
                                        <FreekinLogo color='black' />
                                    </div>
                                </Col>
                            </Row>
                        </Layout>
                        <Layout style={{backgroundColor:'transparent', height:'20vh'}}>
                        </Layout>
                        <Layout style={{backgroundColor:'transparent', height:'45vh'}}>
                            <div style={{margin:'20px'}}>
                                <iframe
                                    src={iframeSrc} width="100%" height="250" frameBorder="0" allowTransparency="true" scrolling="no" />
                            </div>
                        </Layout>
                        <Layout style={{backgroundColor:'transparent', height:'20vh'}}>
                        </Layout>
                    </Content>
                </Layout>
            );
        }
    }
}

export default withRouter(BuyPage);