import { getResponse } from './utils';
const BASE_URL = 'https://igosh.pro/freekin.server';

class Api {
    constructor() {
        this._base_url = BASE_URL;
    }

    getAllLots() {
        return fetch(`${this._base_url}/lot/`, {
            method: 'GET',
            headers: {
            'Content-type': 'application/json'
            },
        })
        .then(getResponse);
    }

    getLotById(id) {
        return fetch(`${this._base_url}/lot/${id}`, {
            method: 'GET',
            headers: {
            'Content-type': 'application/json'
            },
        })
        .then(getResponse);
    }

    getUserInfo(id) {
        return fetch(`${this._base_url}/user/${id}`, {
            method: 'GET',
            headers: {
            'Content-type': 'application/json'
            },
        })
        .then(getResponse);
    }
}

const api = new Api();

export default api;