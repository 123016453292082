import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useFormWithValidation } from '../../hooks/useForm';
import styles from './FormOrder.module.css';
import wallpaper from '../../images/wallpaper.jpg';

const FormOrder = ({lotId, cardNumber, visible, responseError = {}, onClose}) => {
    const {values, handleChange, errors, isValid, resetForm} = useFormWithValidation();
    const history = useHistory();

    useEffect(() => {
        resetForm();
    }, [])

    const toggleButtonDisabled = () => {
        if (Object.values(values).every(value => value.length === 0)) return true;
        return false;
    }

    useEffect(() => {
        toggleButtonDisabled();
    }, [values])

    const handleSubmit = (e) => {
        e.preventDefault();
        
        sendBuyInfo();
        //api.post
        onClose();
    }

    const handleOverlayClose = (event) => {
        if (event.target === event.currentTarget) {
            onClose();
        }
    };

    const sendBuyInfo = () => {
        let contacts = "";

        if(values.email !== ''){
            contacts = values.email;
        }
        if(values.tg !== ''){
            if(contacts !== ''){
                contacts += ',';
            }
            contacts += values.tg;
        }
        if(values.whatsup !== ''){
            if(contacts !== ''){
                contacts += ',';
            }
            contacts += values.whatsup;
        }
        
        if(contacts.length > 0) {
            const requestOptions = {
                method: 'POST',
                headers: {'Content-Type': 'application/json'},
                body: JSON.stringify({email: contacts, contacts: contacts})
            };
            fetch("https://igosh.pro/freekin.server/buy/" + lotId, requestOptions)
                .then(function (response){
                    if(response.ok){
                        history.push('/buy/' + lotId)
                    } else{
                        if(response.status === 409){
                            alert('На данный email уже отправлялся выбранный лот');
                        } else{
                            alert('Ошибка, пожалуйста повторите ваш запрос позже');
                        }
                    }
                });
        } else{
            alert("Пожалуйста, укажите как минимум один Ваш контакт");
        }
    }

    return (
        <div className={`${styles.modal} ${visible && styles.modal_show}`} onMouseDown={handleOverlayClose}>
            <div className={styles.modal__body}>
                <span className={styles.modal__span} onClick={onClose}/>
                <h2 className={styles.modal__title}>Куда отправить секрет?</h2>
                <div className={styles['flex-wrapper']}>
                    <img src={wallpaper} alt='Иконка' className={styles.modal__image} />
                </div>
                <form className={styles.modal__form} onSubmit={handleSubmit}>
                    <ul className={styles.modal__list}>
                        <li className={styles.modal__item}>
                            <input
                                type="email"
                                className={`${styles.modal__input} ${errors.email ? styles.modal__input_error : values.email ? styles.modal__input_validate : styles.modal__input}`}
                                placeholder="Почта"
                                id="email"
                                name="email"
                                value={values.email || ''}
                                onChange={handleChange}
                            />
                            <span className={`${styles.form__error} ${errors.email && 'form__error_show'}`} id="input-error">{errors.email || ""}</span>
                        </li>
                        <li className={styles.modal__item}>
                            <input
                                type="text"
                                className={`${styles.modal__input} ${errors.tg ? styles.modal__input_error : values.tg ? styles.modal__input_validate : styles.modal__input}`}
                                placeholder="Telegram"
                                id="tg"
                                name="tg"
                                value={values.tg || ''}
                                onChange={handleChange}
                            />
                            <span className={`${styles.form__error} ${errors.tg && 'form__error_show'}`} id="input-error">{errors.tg || ""}</span>
                        </li>
                        <li className={styles.modal__item}>
                            <input
                                type="tel"
                                className={`${styles.modal__input} ${errors.whatsapp ? styles.modal__input_error : values.whatsapp ? styles.modal__input_validate : styles.modal__input}`}
                                placeholder="WhatsApp"
                                id="whatsapp"
                                name="whatsapp"
                                value={values.whatsup || ''}
                                onChange={handleChange}
                            />
                            <span className={`${styles.form__error} ${errors.whatsapp && 'form__error_show'}`} id="input-error">{errors.whatsapp || ""}</span>
                        </li>
                        <li>
                            <span className={`form-profile__response-error ${responseError.message && "form-profile__response-message"}`}  >{responseError.error || responseError.message}</span>
                            <button type='submit' className={`${styles.form__submit} ${(!isValid || toggleButtonDisabled()) && styles.form__submit_disabled}`} disabled={!isValid || toggleButtonDisabled()}>Купить секрет</button>
                        </li>
                        <li>
                        </li>
                    </ul>
                </form>
            </div>
        </div>
    )
}

export default FormOrder;
