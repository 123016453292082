import React, { useState, useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import FreekinLogo from "../App/Icons/logo";
import api from '../../utils/api';
import styles from './PersonPage.module.css';

const PersonPage = () => {
    const id = useParams();
    const history = useHistory();
    const [user, setUser] = useState({});

    const personInfo = () => {
        api.getUserInfo(id.id)
            .then(res => {
                setUser(res)
            })
            .catch(err => console.log(err))
    }

    useEffect(() => {
        console.log(id)
        personInfo()
    }, [])

    const getContactType = (contacts) => {
        if(contacts != null){
            if(contacts.includes("t.me")){
                return "Telegram";
            } else if(contacts.includes("instagram")){
                return "Instagram";
            } else if(contacts.includes("facebook")){
                return "Facebook";
            }
        }
        return "Контакты:";
    }
    
    return (
        <div className={styles.wrapper}>
            <header className={styles.header}>
                <span className={styles.button_back} onClick={() => history.goBack()}/>
                <FreekinLogo color='black' />
            </header>
            <main className={styles.main}>
                {user &&
                    <>
                    <img src={user.imageUrl} alt="User Avatar" className={styles.avatar} />
                    <h2 className={styles.username}>{user.name}</h2>
                    <p className={styles.user__summary}>{user.summary}</p>
                    <p className={styles.user__contacts}>{getContactType(user.contacts)}:
                        <a href={user.contacts}> {user.contacts}</a>
                    </p>
                    <p className={styles.user__description}>{user.description}</p>
                    </>
                }
            </main>
        </div>
        
    )
}

export default PersonPage;
