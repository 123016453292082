import React, { Component } from 'react';
import {Grid, Row, Col, Layout, Typography, Image} from 'antd';
import 'antd/dist/antd.css';
import { Button, Space } from 'antd';
import {Link, useHistory, withRouter} from 'react-router-dom';
import FreekinLogo from './Icons/logo.js';
import styles from './OnboardingPage.module.css';

const { Title, Text } = Typography;
const { Header, Footer, Sider, Content } = Layout;

//const siteRoot = "/freekin.site";
const siteRoot = ".";

class OnboardingPage extends Component {

    constructor(props) {
        super(props);
        this.goToLots = this.goToLots.bind(this);
        this.goToHome = this.goToHome.bind(this);
        this.goToNextPage = this.goToNextPage.bind(this);
    }
    goToHome(){
        this.props.history.push(siteRoot + '/onboarding');
    }
    goToLots(){
        this.props.history.push(siteRoot + '/lots');
    }
    goToNextPage(){
        this.props.history.push('/onboarding2');
    }

    render() {
        return (
            <Layout
                className={styles.background}
                style={{backgroundImage: 'url(/onboarding1_small.jpg)'}}
            >
                <Header className={styles.header}>
                    <div align="middle">
                        <FreekinLogo color='white' />
                    </div>
                </Header>
                <Content className={styles.content}>
                    <Layout className={styles.layout}>
                        <Row className={styles.titleWrapper}>
                            <Col span={24}>
                                <h2 className={styles.title}>
                                    ВЫБЕРИ СВОЁ<br/>
                                    ФРИКОВАТОЕ<br/>
                                    МЕСТО И ПОЛУЧИ<br/>
                                    СЕКРЕТ К НЕМУ
                                </h2>
                            </Col>
                        </Row>
                    </Layout>
                    <Layout className={styles.layout}>
                        <Row>
                            <Col span={24}>
                                <p className={styles.description}>
                                    99%, что ты не встретишь<br/>
                                    эти локации сам и не узнаешь<br/>
                                    лайфхаки, которые мы к ним<br/>
                                    нашли
                                </p>
                            </Col>
                        </Row>
                    </Layout>
                    <Layout className={styles.layout}>
                        <Row>
                            <Col>
                                <p className={styles.subDescription}>
                                    *Пока в Москве и Московской области
                                </p>
                            </Col>
                        </Row>
                    </Layout>
                </Content>
                <Footer className={styles.footer}>
                    <Button
                        type="primary"
                        size={"large"}
                        className={styles.goNextButton}
                        block
                        onClick={this.goToNextPage}
                    >
                        Начать
                    </Button>
                </Footer>
            </Layout>
        );
    }
}

export default withRouter(OnboardingPage);
