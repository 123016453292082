import React, { Component } from 'react';
import {Grid, Row, Col, Layout, Typography, Image} from 'antd';
import 'antd/dist/antd.css';
import { Button, Space } from 'antd';
import {Link, useHistory, withRouter} from 'react-router-dom';
import FreekinLogo from "./Icons/logo";

const { Title, Text } = Typography;
const { Header, Footer, Sider, Content } = Layout;

const styles = {
    root:{
        backgroundColor:'white'
        },
    };

//const siteRoot = "/freekin.site";
const siteRoot = ".";

class BuyCompletePage extends Component {

    constructor(props) {
        super(props);
        this.goToHome = this.goToHome.bind(this);
    }
    goToHome(){
        window.location.href ='/onboarding';
    }
    
    render() {

        //const imgName = this.props.match.params.imgName;

        return (
            <Layout style={{height:"100vh", backgroundColor:'white'}}>
                <Header style={{backgroundColor:'white', height:'10vh'}}>
                    <div align="middle" onClick={() => this.goToHome()}>
                        <FreekinLogo color='black' />
                    </div>
                </Header>
                <Content style={{backgroundColor:'white', height:'5vh'}}>
                    <Row style={{marginTop:'30px'}}>
                        <Col span={24}>
                            <div align='middle'>
                                <Title level={3}>ВЫ ПОЛУЧИТЕ СВОЙ СЕКРЕТ</Title>
                            </div>
                        </Col>
                    </Row>
                </Content>
                <Content style={{backgroundColor:'white', height:'5vh'}}>
                    <Row style={{marginTop:'0px'}}>
                        <Col span={24}>
                            <div align='middle'>
                                <Title level={3}>В ТЕЧЕНИЕ 24 ЧАСОВ</Title>
                            </div>
                        </Col>
                    </Row>
                </Content>
                <Content style={{backgroundColor:'white', height:'20vh'}}>
                    <Row>
                        <Col span={24}>
                            {/*<div align="middle">
                                <img src={'https://igosh.pro/shared/freekin/' + imgName} width={80} height={70} style={{margin:0}} />
                            </div>*/}
                        </Col>
                    </Row>
                </Content>
                <Content style={{backgroundColor:'white', height:'30vh'}}>
                    <Row style={{marginTop:'0px', paddingLeft:'50px',paddingRight:'50px'}}>
                        <Col span={24}>
                            <div align='middle'>
                                <Title level={4}>Мы в начале пути и прикрутим оплату чуть позже, а пока свяжемся с тобой по указанным контактам :)</Title>
                            </div>
                        </Col>
                    </Row>
                </Content>
                <Footer style={{backgroundColor:'white', height:'15vh', paddingLeft:'20px', paddingRight:'20px', paddingTop:'0px'}}>
                    <Button type="primary" size={"large"} block style={{backgroundColor:'black', transform:'rotate(-3deg)'}} onClick={this.goToHome}>Ok</Button>
                </Footer>
            </Layout>
        );
    }
}

export default withRouter(BuyCompletePage);