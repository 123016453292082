import React, { Component } from 'react';
import {Grid, Row, Col, Layout, Typography, Image} from 'antd';
import 'antd/dist/antd.css';
import { Button, Space } from 'antd';
import {Link, useHistory, withRouter} from 'react-router-dom';
import {DislikeTwoTone} from "@ant-design/icons";
import ButtonCancel from './Icons/buttonCancel.js';
import ButtonOk from './Icons/buttonOk.js';
//import FreekinLogo from "./Icons/logo";
import SwipeLeft from './Icons/swipeLeft.js';
import SwipeRight from './Icons/swipeRight.js';

const { Title, Text } = Typography;
const { Header, Footer, Sider, Content } = Layout;

//const siteRoot = "/freekin.site";
const siteRoot = ".";

class Onboarding2Page extends Component {

    constructor(props) {
        super(props);
        this.goToLots = this.goToLots.bind(this);
        this.goToHome = this.goToHome.bind(this);
    }
    goToHome(){
        this.props.history.push('/onboarding');
    }
    goToLots(){
        this.props.history.push('/lots');
    }
    
    render() {
        return (
            <Layout onClick={this.goToLots} style={{height:"100vh",backgroundImage: 'url(' + siteRoot + '/onboarding1_small.jpg' + ')', backgroundSize:'cover', backgroundPosition:'unset', backgroundRepeat:"no-repeat"}}>
                <Layout style={{backgroundColor:'transparent', height:'25vh', fontFamily:'Reforma-Grotesk'}}>
                    <div style={{paddingLeft:'20px', paddingTop:'20px', paddingRight:'40px'}}>
                        <Title level={5} style={{color:'white', textAlign:"left", fontSize:'x-large',backgroundColor:'transparent', padding:'5px'}}>
                            НАЖМИ В ЛЮБОЕ МЕСТО ЭКРАНА, ЧТОБЫ ПРОПУСТИТЬ
                        </Title>
                    </div>
                </Layout>
                <Layout style={{backgroundColor:'transparent', height:'30vh'}}>
                    <Row>
                        <Col span={24} style={{backgroundColor:'transparent'}}>
                            <Layout style={{backgroundColor:'transparent', height:'10vh',fontFamily:'Reforma-Grotesk'}}>
                                <Row>
                                    <Col span={12} style={{textAlign:'center'}}>
                                        <div style={{padding:'10px'}}>
                                            <SwipeRight />
                                            <Title level={5} style={{color:'white', textAlign:"right", fontSize:'x-large',backgroundColor:'transparent', padding:'5px'}}>
                                                ПРЕДЫДУЩИЙ ЛОТ
                                            </Title>
                                        </div>
                                    </Col>
                                    <Col span={12} style={{textAlign:'center'}}>
                                        <div style={{padding:'10px'}}>
                                            <SwipeLeft />
                                            <Title level={5} style={{color:'white', textAlign:"left", fontSize:'x-large',backgroundColor:'transparent', padding:'5px'}}>
                                                СЛЕДУЮЩИЙ ЛОТ
                                            </Title>
                                        </div>
                                    </Col>
                                </Row>
                            </Layout>                            
                        </Col>
                    </Row>
                    <Layout style={{backgroundColor:'transparent', height:'15vh'}}>
                    </Layout>
                </Layout>
                <Layout style={{backgroundColor:'transparent', height:'35vh'}}>
                    <Row>
                        <Col span={24} style={{backgroundColor:'transparent'}}>
                            <Layout style={{backgroundColor:'transparent',fontFamily:'Reforma-Grotesk'}}>
                                <Row>
                                    <Col span={12} style={{textAlign:'left'}}>
                                        <div style={{padding:'20px'}}>
                                            <Title level={5} style={{color:'white', textAlign:"left", fontSize:'x-large', backgroundColor:'transparent', padding:'0px'}}>
                                                НЕ НРАВИТСЯ, ПРОПУСТИТЬ
                                            </Title>
                                            <ButtonCancel circleColor='#B6BBC0' iconColor='black' />
                                        </div>
                                    </Col>
                                    <Col span={12} style={{textAlign:"right"}}>
                                        <div style={{padding:'20px'}}>
                                            <Title level={5} style={{color:'white', textAlign:'right', fontSize:'x-large', backgroundColor:'transparent', padding:'0px'}}>
                                                НРАВИТСЯ, ПОСМОТРЕТЬ
                                            </Title>
                                            <ButtonOk circleColor='#FF0099' iconColor='black' />
                                        </div>
                                    </Col>
                                </Row>
                            </Layout>
                        </Col>
                    </Row>
                </Layout>
            </Layout>
        );
    }
}

export default withRouter(Onboarding2Page);