import React, { Component } from 'react'
import {Link} from "react-router-dom";
import GoogleLogin from 'react-google-login';

import {
    isMobile, isSafari
} from "react-device-detect";
import {Grid, Row, Col, Layout, Typography, Image, Avatar, Space, Button, Carousel, Tag} from 'antd';
import {useHistory, withRouter} from 'react-router-dom';
import ButtonCancel from './Icons/buttonCancel.js';
import ButtonOk from './Icons/buttonOk.js';

import {LikeTwoTone, DislikeTwoTone} from "@ant-design/icons";
const { Title, Text } = Typography;
const { Header, Footer, Sider, Content } = Layout;

//const siteRoot = "/freekin.site";
const siteRoot = ".";


class LotsPage extends React.Component {
    
    constructor(props) {
        super(props);
        this.carouselRef = React.createRef();
        this.goToHome = this.goToHome.bind(this);
        this.goToLotDetail = this.goToLotDetail.bind(this);
        this.state = {
            error: null,
            isLoaded: false,
            items: [],
            authAvatarImageUrl: "https://developers.google.com/identity/images/g-logo.png",
            authLoginText: navigator.language.includes("ru") ? "Войти" : "Login",
            authUserId: "",
            authAccessToken: "",
            usersProperties: [],
            currentLotIndex: -1,
        };
    }

    goToHome(){
        this.props.history.push('/onboarding');
    }

    goToLotDetail(lotId, lotNumber){
        localStorage.setItem('lastDetailLotNumber', lotNumber);
        this.props.history.push('/lotDetail/' + lotId);
    }

    componentDidMount() {
        
        fetch("https://igosh.pro/freekin.server/lot/")
        //fetch("http://localhost:5000/lot/")
            .then(res => res.json())
            .then(
                (result) => {
                    this.setState({
                        isLoaded: true,
                        items: result,
                        currentLotIndex: 0
                    });
                },
                // Примечание: важно обрабатывать ошибки именно здесь, а не в блоке catch(),
                // чтобы не перехватывать исключения из ошибок в самих компонентах.
                (error) => {
                    this.setState({
                        isLoaded: true,
                        error
                    });
                }
            )
    }
    componentDidUpdate() {
        let num = localStorage.getItem('lastDetailLotNumber');
        console.log("lastDetailLotNumber:" + num);
        if(num != null){
            this.handleGoTo(num - 1);
        }
    }
    
    handleGoTo = (num) => this.carouselRef.current.goTo(num, true);
    handleNext = () => this.carouselRef.current.next();
    handlePrev = () => this.carouselRef.current.prev();
    
    render() {

        const { error, isLoaded, items, usersProperties, currentLotIndex, carouselRef } = this.state;
        
        if (error) {
            return <div>Ошибка: {error.message}
            </div>;
        } else if (!isLoaded) {
            return <div>Загрузка...</div>;
        } else
        {
            return (
                <Layout style={{height:"100vh"}}>
                    <Carousel effect="scrollx" dotPosition={"top"} ref={this.carouselRef}>
                        {items.filter(item => item.type !== 1).map(item => {
                            return (
                                    <div key={item.id}>
                                        <Layout style={{backgroundColor:'white', height:'100vh', backgroundImage: 'url(' + item.medias[0].url + ')', backgroundSize:'cover', backgroundPosition:'unset', backgroundRepeat:"no-repeat"}}>
                                            <Content>
                                                <div style={{backgroundImage:'linear-gradient(rgba(0,0,0,5.5), rgba(0,0,0,0))'}}>
                                                    <Layout style={{height:"5vh", backgroundColor:'transparent'}}>
                                                    </Layout>
                                                    <Layout style={{height:"25vh", backgroundColor:'transparent'}}>
                                                        <Row>
                                                            <Col span={24} style={{marginTop:'0px', marginLeft:'20px', marginRight:'20px', textAlign:'left'}}>
                                                                <Space>
                                                                    {item.tags.map(item=>{
                                                                        return (
                                                                            <div key={item.id}>
                                                                                <Tag color={"#2656FA"} style={{margin:'0px', borderRadius:'10px'}}>{item.name}</Tag>
                                                                            </div>
                                                                        )
                                                                    })}
                                                                </Space>
                                                            </Col>
                                                        </Row>
                                                        <Row style={{color:'white',textAlign:'left'}}>
                                                            <Title level={3} style={{color:'white', marginLeft:'20px', marginTop:'10px', marginRight:'70px',fontSize:'xx-large',fontFamily:'Reforma-Grotesk'}}>
                                                                {item.caption}
                                                            </Title>
                                                            <Text secondary style={{color:'white', marginLeft:'20px', marginTop:'20px', marginRight:'0px',fontSize:' large'}}>
                                                                {item.location}
                                                            </Text>
                                                        </Row>
                                                    </Layout>
                                                    <Layout style={{height:"10vh", backgroundColor:'transparent'}}>

                                                    </Layout>
                                                </div>
                                                <Layout style={{height:"40vh", backgroundColor:'transparent'}}>

                                                </Layout>
                                                <Layout style={{height:"15vh", backgroundColor:'transparent'}}>
                                                    <Row justify={"center"} style={{alignItems:"center"}}>
                                                        <Col span={6} style={{backgroundColor:"transparent", alignItems:"center"}}>
                                                            <Space style={{width:'100%', justifyContent:"center", padding:'0px'}} size={"large"} onClick={() => this.carouselRef.current.next()}>
                                                                <ButtonCancel circleColor='#B6BBC0' iconColor='black' />
                                                            </Space>
                                                        </Col>
                                                        <Col span={12} style={{backgroundColor:"transparent"}}>
                                                            <Space style={{width:'100%', justifyContent:"center", padding:'0px',fontSize:'medium',fontFamily:'Reforma-Grotesk'}} size={"small"} >
                                                                <Title level={3} style={{color:'white', backgroundColor:'black', padding:'5px', margin:'0px'}}>
                                                                    ЛОТ #{item.number}
                                                                </Title>
                                                                <Title level={3} style={{color:'black', backgroundColor:'white', padding:'5px', margin:'0px'}}>
                                                                    {item.price}Р.
                                                                </Title>
                                                            </Space>
                                                        </Col>
                                                        <Col span={6} style={{backgroundColor:"transparent"}}>
                                                            <Space style={{width:'100%', justifyContent:"center", padding:'0px'}} size={"large"} onClick={() => this.goToLotDetail(item.id, item.number)}>
                                                                <ButtonOk circleColor='#FF0099' iconColor='black'  />
                                                            </Space>
                                                        </Col>
                                                    </Row>
                                                </Layout>
                                            </Content>
                                        </Layout>
                                    </div>
                                  )
                        })}
                    </Carousel>
                </Layout>
            );
        }
    }
}

export default withRouter(LotsPage);